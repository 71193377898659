<!-- System: STA
    Purpose: This compoment will display paypal
            payment information, details and histroy.
-->
<template>
  <v-container>
    <div class="subscription-main-div" v-if="!planUpdate">
      {{ check_plan }}
      <v-container class="container-bg" elevation-5>
        <v-row>
          <!-- Plan Details -->
          <v-col cols="12" lg="4" class="mt-5 mx-auto">
            Your Selected Plan is:
            <v-chip class="ma-2" color="orange" text-color="white">
              {{ planPeriod }}
            </v-chip>
            <v-chip class="ma-2" color="blue" text-color="white">
              {{ planType }}
            </v-chip>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" lg="8" class="mt-5 mx-auto">
            <!-- Cancel Plan Button -->
            <v-btn
              v-if="check_subscription_status"
              max-width="180"
              id="cancel-btn"
              small
              color="error"
              class="float-right col-sm-12 col-md-3 mr-5"
              @click="cancelSubscription"
              >Cancel Subscription
            </v-btn>
            <!-- Reactivate Plan Button -->
            <v-btn
              v-if="!check_subscription_status"
              max-width="220"
              small
              id="reactive-btn"
              color="success"
              class="float-right col-sm-12 col-md-3 mr-5"
              @click="reactiveSubscription"
            >
              Reactivate Subscription
            </v-btn>
            <!-- Change Plan Button -->
            <v-btn
              max-width="180"
              small
              id="update-btn"
              color="success"
              @click="planUpdate = true"
              class="float-right col-sm-12 col-md-3 mr-5"
              >Change Plan
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="container-bg mt-10" elevation-5>
        <v-card-text class="font-weight-bold subtitle-1"
          >Invoices History</v-card-text
        >
        <!-- Paypal Payment Histroy Table -->
        <v-simple-table class="mt-5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Invoice Name</th>
                <th class="text-left">Price</th>
                <th class="text-left">Time</th>
                <th class="text-left">Status</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="invoice.id" v-for="invoice in paypalInvoice">
                <td class="text-left">{{ invoice.invoice_name }}</td>
                <td class="text-left">
                  {{ invoice.price }}
                </td>
                <td class="text-left">
                  {{ new Date(invoice.time).toUTCString() }}
                </td>
                <td class="text-left" v-if="invoice.status == 'COMPLETED'">
                  <v-chip class="ma-2" color="green" text-color="white">
                    Paid
                  </v-chip>
                </td>
                <td
                  class="text-left"
                  v-if="invoice.status == 'UNCOMPLETED' || ''"
                >
                  <v-chip class="ma-2" color="red" text-color="white">
                    Unpaid
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
    </div>
    <!-- Change / Update Plan Component -->
    <div v-if="planUpdate">
      <ChangePlan />
    </div>
  </v-container>
</template>
<script>
import PaymentMethodMixin from "@/components/Billing/PaymentMethodMixin";
import { mapState } from "vuex";
export default {
  mixins: [PaymentMethodMixin],
  components: { ChangePlan: () => import("@/components/Billing/ChangePlan") },
  computed: {
    ...mapState("custom", ["paypal_invoices", "companies_list"]),
    /**
     * Return the subscription status
     */
    check_subscription_status() {
      for (let i = 0; i < this.companies_list.length; i++) {
        if (this.companies_list[i].id == this.$route.params.id) {
          if (
            this.companies_list[i].subscription_canceled &&
            this.companies_list[i].subscription_canceled == "CANCELLED"
          ) {
            return false;
          } else {
            return true;
          }
        }
      }
    },
    check_plan() {
      for (let i = 0; i < this.companies_list.length; i++) {
        if (this.companies_list[i].id == this.$route.params.id) {
          if (this.companies_list[i].plan_type == "monthly_basic") {
            (this.planPeriod = "Monthly"), (this.planType = "Basic");
            return;
          }
          if (this.companies_list[i].plan_type == "monthly_standard") {
            (this.planPeriod = "Monthly"), (this.planType = "Standard");
            return;
          }
          if (this.companies_list[i].plan_type == "yearly_basic") {
            (this.planPeriod = "Yearly"), (this.planType = "Basic");
            return;
          }
          if (this.companies_list[i].plan_type == "yearly_standard") {
            (this.planPeriod = "Yearly"), (this.planType = "Standard");
            return;
          }
        }
      }
    },
  },
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "Invoice Name",
        },
        {
          key: "price",
          label: "Price",
        },
        {
          key: "time",
          label: "Time",
        },
        {
          key: "status",
          label: "Status",
        },
      ],
      planPeriod: "",
      planType: "",
      planUpdate: false,
      paypalInvoice: [],
    };
  },
  methods: {
    /**
     * This function is resposible for
     * cancelling the subscription
     */
    cancelSubscription() {
      this.$store.commit("custom/toggle_loader", true);
      this.$store
        .dispatch("custom/cancel_paypal_subscription", {
          company_id: this.$route.params.id,
        })
        .then(() => {
          this.$store.dispatch("custom/getAllCompaniesList");
          this.$store.commit("custom/toggle_loader", false);
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Subscription Cancelled Successfully",
            snackbarColor: "green",
          });
        })
        .catch(() => {
          this.$store.commit("custom/toggle_loader", false);
        });
    },
    /**
     * This function is resposible for
     * reactive the subscription
     */
    reactiveSubscription() {
      this.$store.commit("custom/toggle_loader", true);
      this.$store
        .dispatch("custom/reactive_paypal_subscription", {
          company_id: this.$route.params.id,
        })
        .then(() => {
          this.$store.dispatch("custom/getAllCompaniesList");
          this.$store.commit("custom/toggle_loader", false);
          this.$root.$emit("snack_bar", {
            show: true,
            message: "Subscription Reactivated Successfully",
            snackbarColor: "green",
          });
        })
        .catch(() => {
          this.$store.commit("custom/toggle_loader", false);
        });
    },
  },
  mounted() {
    this.$store
      .dispatch("custom/get_paypal_invoice_histroy", {
        company_id: this.$route.params.id,
      })
      .then((response) => {
        this.paypalInvoice = response[0];
      })
      .catch((error) => {});
  },
};
</script>
<style scoped>
@media (max-width: 900px) {
  #cancel-btn {
    margin-top: 5%;
  }
  #reactive-btn {
    margin-top: 5%;
  }
  #update-btn {
    margin-top: 5%;
  }
}
.download-invoice {
  color: rgb(32, 32, 238);
  background: #d2e6fb !important;
}
.btn.focus,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: rgb(13, 13, 126) !important;
}
button:focus {
  outline: 1px dotted !important;
  outline: 5px auto -webkit-focus-ring-color !important;
}
/* .subscription-main-div {
  height: 100vh;
} */
.subscription-table {
  overflow-x: auto;
}
</style>
<style>
table.table tbody tr:focus,
table.table tbody tr:active {
  background-color: white !important;
}
table.table tbody tr:hover {
  background-color: white !important;
}
</style>
